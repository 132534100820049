<template>
  <v-card class="mt-5">
    <v-card-title>Все пользователи</v-card-title>
    <v-data-table
      :loading="loading"
      :headers="headers"
      :items="usersData"
      :items-per-page="5"
      class="elevation-1"
      :footer-props="{
        'items-per-page-options': [15, 30, 50, 100],
      }"
      :sort-by="['createdAt']"
      :sort-desc="[true]"
      multi-sort
    >
      <template v-slot:item.Username="{ item }">
        {{ item.Username }}
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ reformatData(item.createdAt) }}
      </template>

      <!-- Слот для статуса -->
      <template v-slot:item.status="{ item }">
        <v-select
          :items="['TRIAL', 'BEGINNER', 'ADVANCED', 'UNPAID']"
          v-model="item.status"
          @change="() => updateProfile(item)"
          label="Статус"
          solo
          dense
          outlined
        >
        </v-select>
      </template>

      <template v-slot:item.subscribedTo="{ item }">
        <v-menu
          ref="menu"
          v-model="item.menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatForDisplay(item.subscribedTo)"
              label="Оплачен до"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="item.subscribedTo"
            @input="updateProfile(item)"
          ></v-date-picker>
        </v-menu>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
          icon
          color="primary"
          :to="{ name: 'User', params: { id: item.tgId } }"
        >
          <v-icon>mdi-page-next</v-icon>
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  name: "Users",
  data() {
    return {
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
        },
        { text: "Telegram ID", value: "tgId" },
        { text: "Логин", value: "username" },
        { text: "Имя", value: "firstName" },
        { text: "Фамилия", value: "lastName" },
        { text: "Дата регистрации", value: "createdAt", sortable: true },
        { text: "Статус", value: "status" },
        { text: "Оплачен до", value: "subscribedTo" },
        { text: "actions", value: "actions" },
      ],
      usersData: [],
      loading: false,
      dateDialog: false,
      editedDate: null,
      editedItem: null,
    };
  },
  methods: {
    reformatData: function(data) {
      return new Date(data).toLocaleString();
    },
    formatForDisplay(date) {
      if (!date) return "Дата не указана";
      return new Date(date).toLocaleDateString();
    },

    formatToDate(dateString) {
      const date = new Date(dateString + "T00:00:00Z");
      return date.toISOString();
    },

    async updateProfile(item) {
      console.log("Update profile called", item);
      const tgProfile = { ...item };
      tgProfile.subscribedTo = this.formatToDate(item.subscribedTo);

      const subscribedTo = new Date(tgProfile.subscribedTo);
      if (subscribedTo < new Date()) {
        tgProfile.status = "UNPAID";
        const index = this.usersData.findIndex(
          (user) => user.tgId === tgProfile.tgId
        );
        if (index !== -1) {
          const updatedUser = {
            ...this.usersData[index],
            status: "UNPAID",
          };
          this.$set(this.usersData, index, updatedUser);
        }
      }
      await this.$axios.post(
        `telegram/profile/update`,
        {
          ...tgProfile,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("authToken")}`,
          },
        }
      );
    },
    isValidDate(d) {
      return d instanceof Date && !isNaN(d);
    },

    prepareDateForPicker(dateStr) {
      const date = new Date(dateStr);
      if (!this.isValidDate(date)) {
        return new Date().toISOString().substr(0, 10);
      }
      return date.toISOString().substr(0, 10);
    },
  },
  mounted: async function() {
    this.loading = true;
    try {
      const response = await this.$axios.get("/telegram/profile", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("authToken")}`,
        },
      });
      this.usersData = response.data.map((user) => ({
        ...user,
        subscribedTo: this.prepareDateForPicker(user.subscribedTo),
      }));
    } catch (error) {
      console.log(error);
    } finally {
      this.loading = false;
    }
  },
};
</script>
