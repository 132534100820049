var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-5"},[_c('v-card-title',[_vm._v("Все пользователи")]),_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loading,"headers":_vm.headers,"items":_vm.usersData,"items-per-page":5,"footer-props":{
      'items-per-page-options': [15, 30, 50, 100],
    },"sort-by":['createdAt'],"sort-desc":[true],"multi-sort":""},scopedSlots:_vm._u([{key:"item.Username",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.Username)+" ")]}},{key:"item.createdAt",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.reformatData(item.createdAt))+" ")]}},{key:"item.status",fn:function(ref){
    var item = ref.item;
return [_c('v-select',{attrs:{"items":['TRIAL', 'BEGINNER', 'ADVANCED', 'UNPAID'],"label":"Статус","solo":"","dense":"","outlined":""},on:{"change":function () { return _vm.updateProfile(item); }},model:{value:(item.status),callback:function ($$v) {_vm.$set(item, "status", $$v)},expression:"item.status"}})]}},{key:"item.subscribedTo",fn:function(ref){
    var item = ref.item;
return [_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatForDisplay(item.subscribedTo),"label":"Оплачен до","prepend-icon":"mdi-calendar","readonly":""}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(item.menu),callback:function ($$v) {_vm.$set(item, "menu", $$v)},expression:"item.menu"}},[_c('v-date-picker',{on:{"input":function($event){return _vm.updateProfile(item)}},model:{value:(item.subscribedTo),callback:function ($$v) {_vm.$set(item, "subscribedTo", $$v)},expression:"item.subscribedTo"}})],1)]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary","to":{ name: 'User', params: { id: item.tgId } }}},[_c('v-icon',[_vm._v("mdi-page-next")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }